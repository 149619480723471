export const appCdnURL = "https://staticbom88.win"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"google","content":"notranslate"},{"name":"robots","content":"index,follow"},{"http-equiv":"Content-Language","content":"vi"},{"name":"google-site-verification","content":"undefined"},{"name":"og:site_name","content":"Nhà cái bom88"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0, viewport-fit=cover, user-scalable=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/assets/brand/favicon.ico"},{"rel":"icon","sizes":"16x16","href":"/assets/brand/favicon-16x16.png"},{"rel":"icon","sizes":"32x32","href":"/assets/brand/favicon-32x32.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/assets/brand/apple-touch-icon.png"}],"style":[],"script":[{"innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n          })(window,document,'script','dataLayer','undefined');","tagPosition":"head"}],"noscript":[{"tagPosition":"bodyOpen","body":true,"innerHTML":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=undefined\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>"}],"htmlAttrs":{"lang":"vi","data-theme":"light"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null